import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Unstable_Grid2';

const Dashboard = () => {
  return (
    <div style={{overflow: "scroll", height: "100vh"}}>
      <Stack direction={"row"} style={{overflow: "scroll"}}>
        <Alert severity='info'>Well. I'm moving out of my flat. So the sensors have to go and get packed away. Thank you for watching some graphs of how hot my front room is. Here are some plant pictures that aren't that impressive</Alert>
        <img style={{width: "50%"}} src='/bigpot.jpg'/>
        <div>The biggest successes</div>
        <div>I'll probably plant the two small ones on the right, they're Carolina reapers which have recently done okay, and they've not missed much sun with how the years gone</div>
        <img style={{width: "50%"}} src='/2ndpot.jpg'/>
        <div>The single birds eye plant that's a little behind, and two more scotch bonnets doing well</div>
        <img style={{width: "50%"}} src='/board.jpg'/>
        <div>The trooper of a PI sending sensor readings every 5 minutes, and the messy breadboard setup which connects the sensors to it</div>
      </Stack>
    </div>
  )
}

export default Dashboard 
