import React from 'react';
import './App.css';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import PageWrapper from './components/pageWrapper/PageWrapper';
import Dashboard from './pages/Dashboard/Dashboard';
import Todo from './pages/Todo/Todo';
import {Helmet} from "react-helmet";
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const client = new ApolloClient({
  uri: 'https://n4mls6ehg2.execute-api.eu-west-2.amazonaws.com/default/chilli-api-serverless/query',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Chilli dashboard</title>
      </Helmet>
      <ApolloProvider client={client}>
        <Paper className="App" square>
          <Dashboard/>
        </Paper>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
